.rich-text {
    line-height: rem(32px);
    font-size: rem(20px);
    @include font-sans-serif;

    p {
        &:first-child {
            margin-top: 0;
        }
    }

    img {
        max-width: 100%;
    }

    h1, h2, h3 {
        margin-bottom: rem(30px);
        max-width: 77rem;
    }

    h2 {
        font-size: rem(36px);
        letter-spacing: 1.5px;
        font-weight: bold;
    }

    h3 {
        font-size: rem(31.5px);
        line-height: 1.4;
        font-weight: bold;
    }

    a {
        color: $color--bistro-brown;
        text-decoration: underline;
        transition: color 0.3s ease-in-out;

        &:hover {
            text-decoration: none;
            color: $color--black;
        }
    }

    .sub-header {
        font-size: rem(20px);
        line-height: 1.4;
        @include font-sans-serif-bold;
    }

    section {
        + section {
            padding-top: $section-spacing;
        }

        &:first-child {
            padding-top: $section-spacing;
        }

        &:last-child {
            padding-bottom: $section-spacing;
        }
    }

    .two-col {
        display: flex;
        $col-padding: 20px;

        .column {
            width: calc(50% - #{$col-padding});
            padding-right: $col-padding;
            max-width: $max-width-chars * 0.5;

            &:last-child {
                padding-left: $col-padding;
                padding-right: 0;
            }
        }
    }

    li {
        padding-left: 20px;
        position: relative;
        padding-left: 1rem;

        &:before {
            content: '•';
            display: inline-block;
            line-height: 0;
            top: 0;
            left: 0;
            position: absolute;
            color: $color--brand-gold;
            @include font-sans-serif;
            font-style: normal;
        }

        p {
            margin-bottom: 0px;
        }
    }

    ul {
        font-size: rem(18px);
        list-style-type: none;

        p {
            font-size: rem(18px);
        }

        > li {
            &:before {
                content: '•';
                display: inline-block;
                top: .4em;
                line-height: 1;
                left: 0;
                position: absolute;
                color: $color--brand-gold;
                @include font-sans-serif;
                font-style: normal;
            }
        }
    }

    ol {
        counter-reset: ol-counter;

        > li {
            &:before {
                content: counter(ol-counter);
                display: inline-block;
                top: .4em;
                line-height: 0.75;
                left: -5px;
                position: absolute;
                color: $color--brand-gold;
                @include font-sans-serif;
                font-style: normal;
                counter-increment: ol-counter;
            }
        }
    }

    .credential-label {
        font-size: rem(18px);
        color: $color--black;
        margin-top: 1.25rem;
        @include font-serif-bold;
    }

    .column .credential-label:first-child {
        margin-top: 0px;
    }

    blockquote {
        font-size: rem(28px);
        margin: 1em 0;
        padding-left: 1.5em;
        position: relative;
        line-height: 1.5;

        @include font-sans-serif-italic;

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 6px;
            background-color: $color--brand-gold;
        }
    }

    figcaption {
        @include font-sans-serif-italic;
        font-size: 1rem;
    }

    figure {
        img {
            width: 100%;
        }
    }
}
