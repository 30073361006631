﻿@media print {
    body{
        margin-top: 50px !important;
    }
    .bio-hero {
        $image-width: 450px;

        min-height: 0;
        padding: 0;
        margin: 0;
        background-color: $color--shadow-grey;
        width: 100%;
        align-items: flex-start;

        .image-container {
            width: 50%;
            padding: 0;
            top: 0;
            display: block;

            img {
                max-width: 100%;
            }
        }

        .bio-content {
            display: block;
            padding: 0 10px 20px;
            box-sizing: border-box;
            width: 50%;
        }

        .name {
            font-size: 22px;

            sup {
                font-family: $font-name-sans-serif;
            }
        }

        .main-title {
            margin-left: 0;

            &:before {
                display: none;
            }
        }

        .email {
            margin-left: 0;
        }

        .office-item {
            font-size: 0.75rem;
            line-height: 1rem;

            + .office-item {
                margin-left: 2rem;
            }
        }

        .disclaimer {
            padding-top: 0;
        }
    }

    .page-bio-detail {

        .main-content {
            section {
                padding-top: 0 !important;
                padding-bottom: 1rem;
            }

            .rich-text {
                font-size: 14px;
                line-height: 1.25rem;

                h1 {
                    margin-bottom: 0;
                }

                h2 {
                    margin-bottom: 0;
                }

                h3 {
                    margin-bottom: 0;
                }

                li {
                    line-height: 1.25rem;
                }

                section {
                    + section {
                        padding-top: 0;
                    }
                }
            }

            .section-header {
                font-size: 20px;
                margin-bottom: 0;
            }

            .read-more-content {
                padding-top: 0;
            }

            .accordion-label {
                font-size: 14px;
                color: $color--black;
                padding: 0;

                @include font-serif-bold;
            }

            .accordion-container {
                height: auto;
                visibility: visible;
                font-size: 14px;
            }

            .inline-list {
                font-size: 10px;

                .list-section {
                    .list-item-link {
                        font-size: 10px;
                        @include font-sans-serif-bold;
                        color: $color--black;
                        position: relative;
                    }
                }
            }
                li {
                    line-height: 1.25rem;
                }
        }

        .related-content {
            background-color: $color--white;
            padding: 0;
            width: 100%;

            .related-tabs{
                display: none !important;
            }

            .section-header {
                font-size: 20px;
                margin-bottom: 0 !important;
            }

            .rich-text {
                font-size: 14px;

                p {
                    color: $color--quartz;
                    margin: 0 !important;
                }
            }

            .insight-list {
                background-color: $color--white;
                .insight-item{
                    padding-top: 0 !important;
                    &:first-child{
                        padding-top: 0 !important;
                    }
                }
            }

            .title {
                font-size: 14px;
            }

            .time-to-read {
                font-size: 10px;

                &:before {
                    width: 16px;
                }
            }

            .article-type {
                margin-top: 0;
                display: block;
                font-size:10px;
            }
            .insight-type{
                font-size:10px;
            }
            date{
                font-size:10px;
            }
        }
    }
}
