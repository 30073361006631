$default__anchor-transition: 0.4s color;
$default__max-text-width: 60ch;

a {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
    transition: $default__anchor-transition;
}
html{
    font-size: $font-base;
    @include screen($bp--tablet){
        font-size: $font-base - 1px;
    };
    @include screen($bp--phone){
        font-size: $font-base - 2px;
    };
}
body {
    @include font-sans-serif;
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    z-index: 0;
}

blockquote {
    quotes: "“" "”" "‘" "’";
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-serif;
    margin-bottom: 0;
    margin-top: 0;
    max-width: $default__max-text-width;
}

figure {
    margin: 0;
}

ol,
ul {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
}

dl {
    margin-bottom: 0;
    margin-top: 0;
}

address {
    font-style: normal;
}

button {
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
    padding: 0;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;

}

input {
    &::placeholder {
        opacity: 1; // TODO: ...at least Chrome and Firefox
    }
}

fieldset,
input {
    border-width: 0;
}

input,
select,
textarea {
    background-color: transparent;
    border-radius: 0;

    &[type="search"] {
        -webkit-appearance: none; // Overrides normalize.css
    }

    &[type="text"] {
        &::-ms-clear {
            display: none;
        }
    }
}

select {
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;

    //&::-ms-expand {
    //    display: none;
    //}
}
