.loading-overlay {
    position: absolute;
    z-index: 20;
    height: 100%;
    width: 100%;
    background-color: rgba(185, 185, 185, 0.5);

    .spinner {
        position: absolute;
        top: 51px;
        left: calc(50% - 24px);
        color: #000;
        font-size: 3rem;
        line-height: 1rem;
        animation: rotate 2s infinite linear;
    }
}

.view-more-loading {
    z-index: 20;

    .spinner {
        left: calc(50% - 24px);
        color: #000;
        font-size: 3rem;
        line-height: 1rem;
        animation: rotate 2s infinite linear;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}