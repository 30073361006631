$font-name-sans-serif: "PT-Sans";
$font-name-sans-serif-italic: "PT-Sans-Italic";
$font-name-serif: "PT-Serif";
$font-name-serif-italic: "PT-Serif-Italic";

// Local path 
$file-path-fonts: "/assets/main/site/fonts/";

$font-base: 16px;

$font-weight-regular: 400;
$font-weight-bold: 700;

@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
    @font-face {
        font-family: $font-family;
        src: url('#{$file-path-fonts}#{$file-path}.eot');
        src: url('#{$file-path-fonts}#{$file-path}.eot?#iefix') format('embedded-opentype'),
             url('#{$file-path-fonts}#{$file-path}.woff') format('woff'),
             url('#{$file-path-fonts}#{$file-path}.ttf') format('truetype'),
             url('#{$file-path-fonts}#{$file-path}.svg##{$font-family}') format('svg');
        font-style: $font-style;
        font-weight: $font-weight;
    }
}

@mixin font-sans-serif {
    font-family: $font-name-sans-serif;
    font-weight: $font-weight-regular;
}

@mixin font-sans-serif-italic {
    font-family: $font-name-sans-serif-italic;
    font-weight: $font-weight-regular;
}

@mixin font-sans-serif-bold {
    font-family: $font-name-sans-serif;
    font-weight: $font-weight-bold;
}

@mixin font-sans-serif-bold-italic {
    font-family: $font-name-sans-serif-italic;
    font-weight: $font-weight-bold;
}

@mixin font-serif {
    font-family: $font-name-serif;
    font-weight: $font-weight-regular;
}

@mixin font-serif-italic {
    font-family: $font-name-serif-italic;
    font-weight: $font-weight-regular;
}

@mixin font-serif-bold {
    font-family: $font-name-serif;
    font-weight: $font-weight-bold;
}

@mixin font-serif-bold-italic {
    font-family: $font-name-serif-italic;
    font-weight: $font-weight-bold;
}

@include font-face($font-name-sans-serif-italic, 'pt-sans-regular-italic', $font-weight-regular, 'normal');
@include font-face($font-name-sans-serif, 'pt-sans-regular', $font-weight-regular, 'normal');
@include font-face($font-name-sans-serif-italic, 'pt-sans-bold-italic', $font-weight-bold, 'normal');
@include font-face($font-name-sans-serif, 'pt-sans-bold', $font-weight-bold, 'normal');
@include font-face($font-name-serif-italic, 'pt-serif-regular-italic', $font-weight-regular, 'normal');
@include font-face($font-name-serif, 'pt-serif-regular', $font-weight-regular, 'normal');
@include font-face($font-name-serif-italic, 'pt-serif-bold-italic', $font-weight-bold, 'normal');
@include font-face($font-name-serif, 'pt-serif-bold', $font-weight-bold, 'normal');
