.read-more {
    display: none;

    .read-more-content {
        > *:first-child {
            margin-top: 0;
        }
    }

    + .read-more-label {
        width: 100%;
        color: $color--teal;
        transition: $transition-text-color;
        display: block;
        text-align: center;
        cursor: pointer;
        position: relative;
        margin-top: 10px;

        &:before {
            content: '';
            width: 100%;
            height: 150px;
            position: absolute;
            left: 0;
            top: -150px;
            background: linear-gradient(rgba($color--white, 0), white 90%);
            cursor: normal;
        }

        &:hover {
            color: $color-gray-darkest;
        }

        &.open {
            &:before {
                display: none;
            }
        }
    }
}

.read-more-disclaimer {
    margin-top: 10px;
}
