// Brand
$color--brand-gold: #be9b39;

// All others
$color--black: #000;
$color--cornflower-blue: #60b1d6;
$color--dark-gray: #6f6f6f;
$color--dark-tan: #988246;
$color--floral-white: #fafaf0;
$color--half-baked: #60b1d6;
$color--teal: #187492;
$color--hemlock: #917a37;
$color--bistro-brown: #956e0a;
$color--jungle-green: #1f9288;
$color--pansy-purple: #731557;
$color--dim-gray: #a4a4a4;
$color--quartz: #4a4a4a;
$color--romance: #fbfbf3;
$color--satin-sheen: #be9b39;
$color--white: #fff;
$color--gondola: #363636;
$color--min-grey: #9d9d9d;
$color-gray-darkest: #2d2d2d;
$color--shadow-grey: #ccc;
$color--gray-medium: #6A6A6A;
$color--light-gold: #f6c63b;