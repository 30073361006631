﻿@media print {
    body {
        margin: 1in 1in 1in 1in !important;
        color: black !important;
        font-size: 12px !important;
    }

    a {
        color: #000 !important;
    }

    .site-nav {
        display: none !important;
    }

    .site-footer {
        display: none !important;
    }

    .navigation-area {
        display: none !important;
    }

    .social-content {
        display: none !important;
    }

    .share {
        display: none !important;
    }

    .page-navigation {
        display: none !important;
    }

    .article-share-tools {
        display: none !important;
    }

    .back-to-top-button {
        display: none !important;
    }

    .share-container {
        display: none !important;
    }

    .bio-content {
        color: black !important;
    }

    .content-wrapper-right {
        padding: 0 !important;
    }

    .read-more {
        visibility: visible !important;
        height: initial !important;
        display: block !important;
    }

    .read-more-label {
        display: none !important;
    }

    .main-content {
        padding: 0 !important;
        width: 100% !important;
    }

    .content-wrapper-right {
        width: 100% !important;
    }

    .lazy-show {
        opacity: 1 !important;
    }

    .lazy-show--from-right, .lazy-show--from-left, .lazy-show--from-bottom {
        transform: none;
    }

    .accordion-container {
        display: block !important;
    }

    .plus-icon {
        display: none !important;
    }

    .accordion {
        border-bottom: 0 !important;
    }

    .view-more {
        display: none !important;
    }

    .related-content {
        color: black !important;
    }

    .related-tabs {
        display: none !important;
    }

    .insight-title {
        color: black !important;
    }

    .content-container {
        width: 100% !important;
        padding-right: 0 !important;
    }

    .section-header {
        color: black !important;
    }

    .date {
        color: black !important;
    }

    .office-link {
        color: black !important;
    }

    .full-number {
        color: black !important;
    }

    .email {
        color: black !important;
    }

    .disclaimer {
        color: black !important;
    }

    .credential-label {
        color: black !important;
    }

    .btn-link {
        display: none !important;
    }

    .title {
        color: black !important;
    }

    .capability-link {
        color: black !important;
    }

    .location-name {
        color: black !important;
    }

    .location-address {
        color: black !important;
    }

    .number-type {
        color: black !important;
    }

    .location-link {
        display: none !important;
    }

    .article-date {
        color: black !important;
    }

    .list-item-link {
        color: black !important;
    }

    .connect-module, .stay-connected {
        display: none !important;
    }

    .attachments-list {
        display: none !important;
    }

    .search-filters, .filters-content, .result-header {
        display: none !important;
    }

    .interior-hero {
        background-image: none !important;
    }

    .time-to-read {
        display: none !important;
    }

    .insights-detail-header {
        background-color: transparent;
        padding: 0;

        .insight-title {
            font-size: 2.5em !important;
        }

        .authors-label, 
        .author-link {
            font-size: 1.25em !important;
        }
    }

    .authors-label {
        color: #000 !important;
    }

    .page-insights-detail {
        .article-background-info {
            padding-top: 1.5rem !important;
        }

        .related-articles {
            background-color: transparent;
            padding: 0;
        }
        .article-content {
            padding: 1.5rem 0 !important;
        }
    }

    .rich-text ul {
        list-style-position: outside;
        list-style-type: disc;
        padding-left: 1.125rem;
    }

    .rich-text li {
        padding-left: 0;
    }

    .rich-text ol > li:before, .rich-text ul > li:before {
        display: none;
    }

    .insight-type, .article-type {
        color: #000 !important;
    }

    .related-articles {
        display: none !important;
    }

    .article-background-info .section-label {
        position: initial;
        top: initial;
        right: initial;
        width: initial;
        min-width: initial;
        color: initial;
        padding: initial;
        background-color: initial;
        text-align: initial;
    }

    .full-width-wrapper {
        max-width: initial;
        margin: initial;
        padding: initial;
    }

    .inline-list {
        .label-section,
        .list-item-link {
            font-size: 1.25em !important;
        }
        .list-section {
            margin-bottom: 1.5rem !important;
        }
    }

    .rich-text {
        font-size: 1.25em;
		
	li{
	font-size: 1em !important;
	font-weight: 100 !important;
	color: $color-gray-darkest !important;
	}
	ul{
	font-size: 1em !important;
	font-weight: 100 !important;
	}
    }
}
