@use "sass:math";

@mixin filler-link(
    $background-color--initial: null,
    $color---initial: $color--white,
    $background-color--hover: $color--half-baked,
    $color---hover: $color--black
) {
    $transition-duration: 0.5s;

    color: $color---initial;
    font-weight: 700;
    display: inline-block;
    letter-spacing: 1px;
    overflow: hidden;
    padding: 0.9375rem 1.4375rem;
    position: relative;
    text-transform: uppercase;
    transition: color $transition-duration;
    z-index: 1;
    &::before {
        $border: 2px solid;

        background-color: $background-color--initial;
        box-sizing: border-box;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -10;
        border: $border $background-color--hover;
    }

    &::after {
        bottom: 0;
        content: "";
        height: 120%;
        left: -10%;
        position: absolute;
        transition: width $transition-duration;
        width: 0;
        z-index: -5;

        @if $background-color--hover {
            background-color: $background-color--hover;
        } @else {
            background-color: $color---initial;
        }
    }
    
    @include screen($bp--tablet + 1, 'min'){
        &:hover {
            color: $color---hover;
            cursor: pointer;

            &::after {
                left: -10%;
                width: 120%;
            }
        }
        }
}

@mixin full-width() {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
}

@mixin image-overlay-gradient($position: bottom, $z-index: 0) {
    position: relative;

    &::after {
        $gradient-color: #fff;

        #{$position}: 0;

        background: linear-gradient(to #{$position}, rgba($gradient-color, 0), rgba($gradient-color, 1));
        content: "";
        height: 25%;
        position: absolute;
        width: 100%;
        z-index: $z-index;
    }
}

@mixin hover-box-shadow(
    $background-color--initial: null,
    $background-color--hover: null,
    $color---initial: null,
    $color---hover: null,
    $z-index: 0
) {
    $transition-duration: 0.5s;

    background-color: $background-color--initial;
    color: $color---initial;
    position: relative;
    transition:
        background-color $transition-duration,
        color $transition-duration,
        box-shadow $transition-duration,
        transform $transition-duration;
    z-index: $z-index;

    &:hover {
        // TODO: make this into a variable
        box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.1), -5px -5px 20px 3px rgba(0, 0, 0, 0.1);
        transform: scale(1.015);
        z-index: $z-index + 1;
    }
}

@mixin image-placeholder($image-width, $image-height) {
    padding-bottom: math.div($image-height, $image-width) * 100%;
}

// https://css-tricks.com/snippets/sass/tint-shade-functions
@function shade($color, $percentage) {
    @return mix(#000, $color, $percentage);
}

// https://css-tricks.com/snippets/sass/tint-shade-functions
@function tint($color, $percentage) {
    @return mix(#fff, $color, $percentage);
}

@mixin underline-link($color: inherit) {
    display: inline-block;
    margin-bottom: 0.125rem;
    position: relative;

    &:hover {
        &::after {
            transform: scaleX(1);
        }
    }

    &::after {
        background-color: $color;
        bottom: -0.125rem;
        content: "";
        height: 0.125rem;
        left: 0;
        position: absolute;
        transform: scaleX(0);
        transform-origin: 0% 0%;
        transition: transform 0.5s;
        width: 100%;
    }

    &.is-active {
        &:after {
            transform: scaleX(1);
        }
    }
}

@mixin screen($device-width, $type: max) {
  @if $type == 'min' {
    @media screen and (min-width: $device-width + 1) {
        @content;
    }
  }

  @else {
    @media screen and (max-width: $device-width) {
      @content
    }
  }
}

@mixin media-height($device-height) {
  @media screen and (max-height: $device-height) {
    @content
  }
}
