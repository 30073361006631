$icomoon-font-family: 'icomoon';
$icomoon-font-path: '/assets/main/site/fonts/icomoon/';
$icon-plus: "\e900";
$icon-facebook: "\e901";
$icon-linkedin: "\e902";
$icon-twitter: "\e903";
$icon-logo: "\e904";
$icon-close: "\e905";
$icon-angle-right: "\e906";
$icon-print: "\f02f";
$icon-envelope: "\f0e0";
$icon-pdf: "\f1c1";

@font-face {
    font-family: 'icomoon';
    src: url($icomoon-font-path + 'icomoon.eot?g0nj0q');
    src: url($icomoon-font-path + 'icomoon.eot?g0nj0q#iefix') format('embedded-opentype'), url($icomoon-font-path + 'icomoon.ttf?g0nj0q') format('truetype'), url($icomoon-font-path + 'icomoon.woff?g0nj0q') format('woff'), url($icomoon-font-path + 'icomoon.svg?g0nj0q#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-plus:before {
  content: "\e900";
}
.icon-facebook:before {
  content: "\e901";
}
.icon-linkedin:before {
  content: "\e902";
}
.icon-Twitter:before {
  content: "\e903";
}
.icon-logo:before {
  content: "\e904";
}
.icon-close:before {
  content: "\e905";
}
.icon-angle-right:before {
  content: "\e906";
}
.icon-print:before {
  content: "\f02f";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-pdf:before {
  content: "\f1c1";
}
.icon-spinner:before {
  content: "\e97a";
}
.icon-spinner2:before {
  content: "\e97b";
}
.icon-spinner3:before {
  content: "\e97c";
}
.icon-spinner4:before {
  content: "\e97d";
}
.icon-spinner5:before {
  content: "\e97e";
}
.icon-spinner6:before {
  content: "\e97f";
}
.icon-spinner7:before {
  content: "\e980";
}
.icon-spinner8:before {
  content: "\e981";
}
.icon-spinner9:before {
  content: "\e982";
}
.icon-spinner10:before {
  content: "\e983";
}
.icon-spinner11:before {
  content: "\e984";
}
