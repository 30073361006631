.content-wrapper-right {
    padding: 0 15% 0 27%;
    width: 58%;
    
    @include screen($bp--tablet)
    {
        width: 65%;
    }

    @include screen($bp--phone){
        $spacing: 1rem;
        width: calc(100% - #{$spacing * 2});
        padding: 0 $spacing;
    }
}

.full-width-wrapper {
    $padding: 1rem;

    padding-left: $padding;
    padding-right: $padding;
    max-width: rem(1250px) + $padding * 2;
    margin-left: auto;
    margin-right: auto;
}
