$lazy-show-translate-x-dur: 0.75s;
$lazy-show-translate-y-duration: 1s;
$lazy-show-translate-x-percent: 10%;
$lazy-show-translate-y-percent: 20%;

// Block
.lazy-show {
    &.lazy-show--is-in-view {
        opacity: 1;
        transform: translateX(0);
    }
}

// Block Modifiers
.lazy-show--from-bottom {
    transform: translateY($lazy-show-translate-y-percent);
}

.lazy-show--from-left {
    transform: translateX(-$lazy-show-translate-x-percent);
}

.lazy-show--from-right {
    transform: translateX($lazy-show-translate-x-percent);
    @include screen($bp--tablet){
        transform: translateY($lazy-show-translate-y-percent);
    }
}

.lazy-show--from-top {
    transform: translateY(-$lazy-show-translate-y-percent);
}

.lazy-show--from-bottom,
.lazy-show--from-top {
    transition: opacity $lazy-show-translate-y-duration,
        transform $lazy-show-translate-y-duration;

    .lazy-show--from-left,
    .lazy-show--from-right {
        $transition-delay: 0.25s;

        transition: opacity $lazy-show-translate-x-dur $transition-delay * 1.5,
            transform $lazy-show-translate-x-dur $transition-delay * 1.5;
    }

    @include screen($bp--tablet){
        .lazy-show--from-right{
                transition: opacity $lazy-show-translate-y-duration,
        transform $lazy-show-translate-y-duration;
        }
    }
}

.lazy-show--from-left,
.lazy-show--from-right {
    opacity: 0;
    transition: transform $lazy-show-translate-x-dur .3s, opacity $lazy-show-translate-x-dur .3s;
}

    @include screen($bp--tablet){
        .lazy-show--from-right{
                opacity: inherit;
        transition: inherit;
        }
    }
